
export const orange = {
  50: '#FFF5E6',
  100: '#FFE0B3',
  200: '#FECB81',
  300: '#FEB64F',
  400: '#FEAC34',
  500: '#FD9726',
  600: '#E68721',
  700: '#B36A18',
  800: '#995B13',
  900: '#4C2D05',
};

// TODO GCR: These are just the tailwindcss default
// yellow colors, get a designer
export const yellow = {
50: '#fefce8',
100: '#fef9c3',
200: '#fef08a',
300: '#fde047',
400: '#facc15',
500: '#eab308',
600: '#ca8a04',
700: '#a16207',
800: '#854d0e',
900: '#713f12',
}

export const blue = {
  50: '#E9F0F6',
  75: '#E2EBF2',
  100: '#BCD3E4',
  200: '#90B6D2',
  300: '#6399BF',
  400: '#4D8AB6',
  500: '#256ea2',
  600: '#1E6294',
  700: '#164C73',
  800: '#103752',
  900: '#0A2131',
};

// Gray-blue, modified from blue above
export const slate = {
  50: '#f5f9fc',
  100: '#e8f4fc',
  200: '#d7eaf7',
  300: '#c7e0f2',
  400: '#abc4d6',
  500: '#6b8699',
  600: '#4a6578',
  700: '#334959',
  800: '#283742',
  900: '#1d2830',
};

export const cyan = {
  50: '#EBF7FD',
  100: '#B9E6F8',
  200: '#88D5F3',
  300: '#5DC4EE',
  400: '#45BBEC',
  500: '#1CAAE7',
  600: '#1899D0',
  700: '#1077A2',
  800: '#0C5474',
  900: '#063345',
}

// TODO GCR: These are just the tailwinds purple colors...
// get a designer
export const purple = {
  50: '#faf5ff',
  100: '#f3e8ff',
  200: '#e9d5ff',
  300: '#d8b4fe',
  400: '#c084fc',
  500: '#a855f7',
  600: '#9333ea',
  700: '#7e22ce',
  800: '#6b21a8',
  900: '#581c87',
}

export const white = {
  50: '#FFFFFF',
  100: '#FFFFFF',
  200: '#FFFFFF',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#FFFFFF',
  600: '#FFFFFF',
  700: '#FFFFFF',
  800: '#FFFFFF',
  900: '#FFFFFF',
}

export const gray = {
  50: '#F0F0F0',
  75: '#EDEDED',
  100: '#D1D1D1',
  200: '#C2C2C2',
  300: '#A3A3A3',
  400: '#858585',
  500: '#666666',
  600: '#525252',
  700: '#3D3D3D',
  800: '#292929',
  900: '#1F1F1F',
}

export const green = {
  50: '#CCF0E5',
  100: '#99E2CB',
  200: '#80DBBE',
  300: '#4DCCA3',
  400: '#32C596',
  500: '#00B67C',
  600: '#149263',
  700: '#0F7F57',
  800: '#085B3E',
  900: '#033724',
}

export const red = {
  50: '#F9BBBE',
  100: '#F58D93',
  200: '#F15F68',
  300: '#EF4952',
  400: '#ED323D',
  500: '#EB1B27',
  600: '#BC161F',
  700: '#8D1018',
  800: '#5E0A10',
  900: '#2F0408',
}

export const black = {
  50: '#000000',
  100: '#000000',
  200: '#000000',
  300: '#000000',
  400: '#000000',
  500: '#000000',
  600: '#000000',
  700: '#000000',
  800: '#000000',
  900: '#000000',
}

export const colors = {
  red,
  orange,
  yellow,
  green,
  blue,
  cyan,
  purple,
  gray,
  slate,
  white,
  black,
};